<template>
  <div>
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <h4 class="mb-0">Basic Information</h4>
    </div>

    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <!-- Form: Personal Info Form -->
      <b-row class="mt-1">
        <!-- Field: Cohort Name -->
        <b-col cols="12" lg="4" md="6" xs="12">
          <b-form-group label="Name" label-for="cohort-name">
            <b-form-input id="cohort-name" required v-model="cohortDataInfo.name" />
          </b-form-group>
        </b-col>

        <!-- Field: Cohort Password -->
        <b-col cols="12" lg="4" md="6" xs="12">
          <b-form-group label="Password" label-for="cohort-password">
            <b-form-input id="cohort-password" required v-model="cohortDataInfo.password" />
          </b-form-group>
        </b-col>

        <!-- Field: Status -->
        <b-col cols="12" lg="4" md="6" xs="12">
          <b-form-group label="Status" label-for="cohort-status">
            <v-select
              v-model="cohortDataInfo.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :clearable="false"
              required
              input-id="cohort-status"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-2 w-100">
        <b-col cols="12" lg="12">
          <b-button
            variant="primary"
            class="float-right"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="doSaveChanges"
          >
            Save Changes
          </b-button>
          <b-button
            variant="outline-secondary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="$router.back()"
          >
            <feather-icon icon="ArrowLeftIcon" />
            Return
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton } from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { onUnmounted, ref } from '@vue/composition-api';
import vSelect from 'vue-select';
import store from '@/store';
import placementStoreModule from '../placementStoreModule';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import useCohortsList from '../cohort-list/useCohortList';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    ToastificationContent,
  },
  props: {
    cohortData: {
      type: Object,
      required: true,
    },
  },
  watch: {
    cohortData: function () {
      this.inheritSelects();
    },
  },
  methods: {
    inheritSelects() {
      if (this.cohortData.status && !this.cohortData.status.value) {
        this.cohortDataInfo.status = this.statusOptions.filter((s) => s.value === this.cohortData.status)[0];
      }
    },
    doSaveChanges() {
      const pwd = this.cohortDataInfo;

      const payload = {
        name: pwd.name,
        password: pwd.password,
        status: pwd.status.value,
      };

      store
        .dispatch('app-placement/updateCohort', { cohortId: pwd.id, cohortData: payload })
        .then((response) => {
          this.$emit('update:cohort-data', response.data);

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Cohort Edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit cohort',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
  },
  mounted() {
    this.inheritSelects();
  },
  setup(props) {
    const cohortDataInfo = ref(props.cohortData);

    const COHORT_APP_STORE_MODULE_NAME = 'app-placement';

    // Register module
    if (!store.hasModule(COHORT_APP_STORE_MODULE_NAME))
      store.registerModule(COHORT_APP_STORE_MODULE_NAME, placementStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COHORT_APP_STORE_MODULE_NAME)) store.unregisterModule(COHORT_APP_STORE_MODULE_NAME);
    });

    const { statusOptions } = useCohortsList();

    return {
      cohortDataInfo,
      statusOptions,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
